import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout canonical="https://cannoncustomwoodworking.com/404/" title="404 - Page Not Found" description="The page you were looking for cannot be found.">
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
